import React from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import Slider from "react-slick";
import Specialists from "../Specialists/Specialists";
import { Icon } from "@iconify/react";

const SpecialistsSlider = ({ data }) => {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-arrow-left slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="fa-solid:angle-left" />
    </div>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-arrow-right slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="fa-solid:angle-right" />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    loop: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
  return (
    <section id="doctors">
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading
        title="Our Products"
        subTitle="At Diagcon, we offer a comprehensive range of pharmaceutical products and medical equipment to cater to diverse healthcare needs. From prescription and over-the-counter medications to advanced diagnostic tools, surgical instruments, and healthcare supplies, we ensure the highest standards of quality and reliability. Our extensive product portfolio is designed to support medical professionals, hospitals, clinics, and pharmacies in delivering exceptional care."
      />
      <div className="container">
        <Slider {...settings} className="st-slider-style2">
          {data.map((elements, index) => (
            <Specialists {...elements} key={index} />
          ))}
        </Slider>
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
    </section>
  );
};

export default SpecialistsSlider;
