import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Preloader from "../Preloader/Preloader";

const headerData = {
  logo: "/images/logoC.png",
};

const footerData = {
  logo: "/images/logoC.png",
  bgImg: "/images/footer-bg.png",
  subTitle:
    "Diagcon is your trusted partner in pharmaceuticals and medical equipment, providing high-quality solutions to meet diverse healthcare needs.",
};
const Layout = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <Header data={headerData} />
          <Outlet />
          <Footer data={footerData} />
        </>
      )}
    </>
  );
};

export default Layout;
