// import LocationInMap from "../Components/Map/LocationInMap";
import Contact from "../Components/Contact/Contact";
import Department from "../Components/Department/Department";
import Appointment from "../Components/Appointment/Appointment";
import About from "../Components/About/About";
import Iconbox from "../Components/Iconbox/Iconbox";
// import PostWrapper from "../Components/Post/PostWrapper";
import SpecialistsSlider from "../Components/Slider/SpecialistsSlider";
import TestimonialSlider from "../Components/Slider/TestimonialSlider";
import BrandSlider from "../Components/Slider/BrandSlider";
import Newsletter from "../Components/Newsletter/Newsletter";
import Accordion from "../Components/Accordion/Accordion";
// import PriceSlider from "../Components/Slider/PriceSlider";
import Funfact from "../Components/Funfact/Funfact";
// import BeforeAfter from "../Components/BeforeAfter/BeforeAfter";
import MasonryGallery from "../Components/Gallery/Gallery";
import Hero12 from "../Components/Hero/Hero12";

const heroData = {
  bgImg: "images/hero-bg.jpg",
  bgShape: "shape/hero-shape.png",
  sliderImages: [
    {
      img: "images/hero-img.png",
    },
    {
      img: "images/hero-img1.png",
    },
    {
      img: "images/hero-img2.png",
    },
    {
      img: "images/hero-img.png",
    },
    {
      img: "images/hero-img1.png",
    },
    {
      img: "images/hero-img2.png",
    },
  ],
  title: ["Crutches", "Laboratory", "Cardiology", "Dentist", "Neurology"],
};

const iconboxData = [
  {
    bg: "purple",
    icon: "icons/icon1.svg",
    title: "Qualified Doctors",
    subTitle:
      "Lorem ipsum dolor sit amet consectet adipis sed do eiusmod tempor inc ididunt utid labore.",
  },
  {
    bg: "green",
    icon: "icons/icon2.svg",
    title: "24 Hours Service",
    subTitle:
      "Lorem ipsum dolor sit amet consectet adipis sed do eiusmod tempor inc ididunt utid labore.",
  },
  {
    bg: "red",
    icon: "icons/icon3.svg",
    title: "Need Emergency",
    subTitle:
      "Lorem ipsum dolor sit amet consectet adipis sed do eiusmod tempor inc ididunt utid labore.",
  },
];

const aboutData = {
  title: "Diagnostic Consolation: Legacy of Excellence in Healthcare",
  subTitle:
    "Under the visionary leadership of <strong>Muhammad Arif Mithani</strong>, Founder and CEO, <strong>Diagnostic Consolation</strong> has grown to be a trusted name in the field of diagnostics and healthcare consultancy. With decades of expertise in biochemistry and pathology, the institution continues to uphold a tradition of precision, integrity, and innovation.<br /><br />  Founded on the principles of quality and care, Diagnostic Consolation has been committed to providing reliable and effective healthcare solutions. Our team of dedicated professionals works tirelessly to ensure the highest standards in diagnostic services, delivering accuracy and trust to every patient and institution we serve. <br /><br /> As a pioneer in healthcare, Muhammad Arif Mithani’s leadership reflects a passion for service and community welfare, which remains at the core of Diagnostic Consolation’s mission. We are proud to carry forward this legacy, contributing meaningfully to the advancement of healthcare.",

  avater: {
    img: "images/founder.jpeg",
    name: "Muhammad Arif Mithani",
    designation: "Founder & CEO",
  },
  timeTable: [
    {
      day: "Monday",
      startTime: "9:00",
      endTime: "5:00",
    },
    {
      day: "Tuesday",
      startTime: "9:00",
      endTime: "5:00",
    },
    {
      day: "Wednesday",
      startTime: "9:00",
      endTime: "5:00",
    },
    {
      day: "Thursday",
      startTime: "9:00",
      endTime: "5:00",
    },
    {
      day: "Friday",
      startTime: "9:00",
      endTime: "5:00",
    },
    {
      day: "Saturday",
      startTime: "9:00",
      endTime: "5:00",
    },
  ],
  contact: "(+92) - 333 3422322",
};

const specialistData = [
  {
    img: "images/moxi.jpg",
    name: "Moximax",
    // designation: "Urology",
    // authorLink: "/doctor-profile",
  },
  {
    img: "images/eso.jpg",
    name: "Esopace",
    // designation: "Cardiology",
    // authorLink: "/doctor-profile",
  },
  {
    img: "images/pre.jpg",
    name: "Preloid",
    // designation: "Neurosurgery",
    // authorLink: "/doctor-profile",
  },
  {
    img: "images/lacto.jpg",
    name: "Lacto-S",
    // designation: "Surgery",
    // authorLink: "/doctor-profile",
  },
  {
    img: "images/rash.jpg",
    name: "Nappy Rash",
    // designation: "Surgery",
    // authorLink: "/doctor-profile",
  },
  {
    img: "images/colic.jpg",
    name: "Colic Drops",
    // designation: "Surgery",
    // authorLink: "/doctor-profile",
  },
  {
    img: "images/hemo.jpg",
    name: "Hemo+",
    // designation: "Surgery",
    // authorLink: "/doctor-profile",
  },
  {
    img: "images/toot.jpg",
    name: "Toot Siyah",
    // designation: "Surgery",
    // authorLink: "/doctor-profile",
  },
  {
    img: "images/arq.jpg",
    name: "Arq-e-Gulaab",
    // designation: "Surgery",
    // authorLink: "/doctor-profile",
  },
  {
    img: "images/neem.jpg",
    name: "Organic Neem Soap",
    // designation: "Surgery",
    // authorLink: "/doctor-profile",
  },
];

const beforeAfterData = {
  bgImg: "/images/before-after-bg.jpg",
  beforeImg: "/images/after.jpg",
  afterImg: "images/before.jpg",
};

const testimonialData = [
  {
    // img: "images/avatar2.png",
    name: "Hassan Bilgrami",
    designation: "CEO",
    subTitle:
      "Mr. Arif Mithani is one of the pioneers in amongst Pharmacy Graduates in Pakistan. Diagnostic Consolation had exclusively represented renowned Multinational Brands and helped them make inroads in complex Healthcare market. This success was possible due to focus on complying to high professional standards.",
  },
  {
    // img: "images/avatar3.png",
    name: "Francis Jara",
    designation: "Biographer",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    // img: "images/avatar4.png",
    name: "David Baer",
    designation: "UX Designer",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  // {
  //   img: "images/avatar2.png",
  //   name: "Ralph Jones",
  //   designation: "Executive",
  //   subTitle:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  // },
  // {
  //   img: "images/avatar3.png",
  //   name: "Francis Jara",
  //   designation: "Biographer",
  //   subTitle:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  // },
  // {
  //   img: "images/avatar4.png",
  //   name: "David Baer",
  //   designation: "UX Designer",
  //   subTitle:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  // },
];

const priceData = [
  {
    title: "Blood Test",
    price: "39",
    featureList: [
      {
        title: "First Description",
        status: "1",
      },
      {
        title: "Second Description",
        status: "1",
      },
      {
        title: "Third Description",
        status: "1",
      },
      {
        title: "Fourth Description",
        status: "0",
      },
      {
        title: "Fifth Description",
        status: "0",
      },
    ],
  },
  {
    title: "Hemoglobin Test",
    price: "89",
    featureList: [
      {
        title: "First Description",
        status: "1",
      },
      {
        title: "Second Description",
        status: "1",
      },
      {
        title: "Third Description",
        status: "1",
      },
      {
        title: "Fourth Description",
        status: "1",
      },
      {
        title: "Fifth Description",
        status: "0",
      },
    ],
  },
  {
    title: "Homocysteine Test",
    price: "150",
    featureList: [
      {
        title: "First Description",
        status: "1",
      },
      {
        title: "Second Description",
        status: "1",
      },
      {
        title: "Third Description",
        status: "1",
      },
      {
        title: "Fourth Description",
        status: "1",
      },
      {
        title: "Fifth Description",
        status: "1",
      },
    ],
  },
  {
    title: "Blood Test",
    price: "39",
    featureList: [
      {
        title: "First Description",
        status: "1",
      },
      {
        title: "Second Description",
        status: "1",
      },
      {
        title: "Third Description",
        status: "1",
      },
      {
        title: "Fourth Description",
        status: "0",
      },
      {
        title: "Fifth Description",
        status: "0",
      },
    ],
  },
  {
    title: "Hemoglobin Test",
    price: "89",
    featureList: [
      {
        title: "First Description",
        status: "1",
      },
      {
        title: "Second Description",
        status: "1",
      },
      {
        title: "Third Description",
        status: "1",
      },
      {
        title: "Fourth Description",
        status: "1",
      },
      {
        title: "Fifth Description",
        status: "0",
      },
    ],
  },
  {
    title: "Homocysteine Test",
    price: "150",
    featureList: [
      {
        title: "First Description",
        status: "1",
      },
      {
        title: "Second Description",
        status: "1",
      },
      {
        title: "Third Description",
        status: "1",
      },
      {
        title: "Fourth Description",
        status: "1",
      },
      {
        title: "Fifth Description",
        status: "1",
      },
    ],
  },
];

const faqData = {
  title: "Just Answer the Questions",
  img: "images/faq-img.png",
  bgImg: "shape/faq-bg.svg",
  faqItems: [
    {
      title: "What types of hospital supplies do you offer?",
      content: `We provide a wide range of medical supplies, including surgical instruments, diagnostic equipment, disposable items, and personal protective equipment (PPE).`,
    },
    {
      title: "Do you offer bulk discounts for large orders?",
      content: `Yes, we provide discounts for bulk purchases. Please contact our sales team for details on pricing and order volumes.`,
    },
    {
      title: "What is your delivery timeline for hospital supplies?",
      content: `Delivery timelines vary depending on the order size and location. Typically, orders are delivered within 2–5 business days. Urgent requests can be accommodated based on availability.`,
    },
    {
      title: "Do you supply internationally?",
      content: `Yes, we ship hospital supplies to international locations. Please reach out to us to discuss shipping options and costs for your region.`,
    },
    {
      title: "Can I return or exchange a product?",
      content: `We accept returns and exchanges for defective or incorrect items within 7 days of delivery. Please review our return policy or contact our customer service for assistance.`,
    },
  ],
};

const newsletterData = {
  bgImg: "images/news-letter-bg.png",
  contact: "(+01) - 234 567 890",
};

const postData = [
  {
    img: "images/blog1.jpg",
    title: "Working in emergency medicine",
    date: "Aug 23, 2020",
    author: "Albert Brian",
    authorLink: "",
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: "/post/post_details",
  },
  {
    img: "images/blog2.jpg",
    title: "Individual treatment & assistance",
    date: "Aug 22, 2020",
    author: "William Juarez",
    authorLink: "",
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: "/post/post_details",
  },
  {
    img: "images/blog3.jpg",
    title: "Child’s first hospital visit",
    date: "Aug 21, 2020",
    author: "Jamse Lewis",
    authorLink: "",
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: "/post/post_details",
  },
  {
    img: "images/blog3.jpg",
    title: "Child’s first hospital visit",
    date: "Aug 21, 2020",
    author: "Jamse Lewis",
    authorLink: "",
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: "/post/post_details",
  },
];

const brandData = [
  {
    // bg: "orange",
    img: "images/lci.png",
  },
  {
    // bg: "blue",
    img: "images/braun.png",
  },
  {
    // bg: "red",
    img: "images/creek.jpeg",
  },
  {
    // bg: "green",
    img: "images/to.jpeg",
  },
  // {
  //   bg: "dip-blue",
  //   img: "images/client5.png",
  // },
  // {
  //   bg: "orange",
  //   img: "images/client1.png",
  // },
  // {
  //   bg: "blue",
  //   img: "images/client2.png",
  // },
  // {
  //   bg: "red",
  //   img: "images/client3.png",
  // },
  // {
  //   bg: "green",
  //   img: "images/client4.png",
  // },
  // {
  //   bg: "dip-blue",
  //   img: "images/client5.png",
  // },
];

// const mapLocationURL = "https://maps.app.goo.gl/Gr8p3JYVhrxLXDXi6";

const Home12 = () => {
  return (
    <>
      <Hero12 data={heroData} />
      <About data={aboutData} />
      <SpecialistsSlider data={specialistData} />
      <BrandSlider data={brandData} />
      <Department />
      <Appointment />
      {/* <MasonryGallery /> */}
      {/* <BeforeAfter data={beforeAfterData} /> */}
      <TestimonialSlider data={testimonialData} />
      <Funfact />
      {/* <PriceSlider data={priceData} /> */}
      <Accordion data={faqData} />
      <Newsletter data={newsletterData} />
      {/* <PostWrapper data={postData} /> */}
      <Contact />
      {/* <LocationInMap data={mapLocationURL} /> */}
    </>
  );
};

export default Home12;
