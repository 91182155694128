import React, { useState } from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import { Icon } from "@iconify/react";

const Appointment = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
    department: "",
    doctor: "",
    msg: "",
  });

  // Handler for input field changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.target);
    formData.append("access_key", "fcc74231-656a-425b-a54f-aff38354fadb");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      setFormData({
        name: "",
        email: "",
        phone: "",
        date: "",
        department: "",
        doctor: "",
        msg: "",
      });
      setLoading(false);
    }
  };

  return (
    <section id="appointment" className="st-shape-wrap st-gray-bg">
      <div className="st-shape4">
        <img src="/shape/section_shape.png" alt="/shape/section_shape.png" />
      </div>
      <div className="st-shape6">
        <img src="/shape/contact-shape3.svg" alt="/shape/contact-shape3.svg" />
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading
        title="Make an appointment"
        subTitle="At Diagcon, we understand the importance of timely access to quality healthcare solutions. That’s why we’ve made it easy for you to book an appointment with our team of experts. Whether you need a consultation on pharmaceutical products, advice on medical equipment, or guidance for your healthcare facility, we’re here to assist."
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <form
              method="POST"
              className="st-appointment-form"
              id="appointment-form"
              onSubmit={onSubmit}
            >
              <input
                type="hidden"
                name="from_name"
                value="Nischinto Medical Appoinment"
              />
              <input type="hidden" name="replyto" value="custom@gmail.com" />
              <div id="st-alert1" />
              <div className="row">
                <div className="col-lg-6">
                  <div className="st-form-field st-style1">
                    <label>Full Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Full name"
                      onChange={handleInputChange}
                      value={formData.name}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="st-form-field st-style1">
                    <label>Email Address</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="example@gmail.com"
                      onChange={handleInputChange}
                      value={formData.email}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="st-form-field st-style1">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="+92 12345678"
                      onChange={handleInputChange}
                      value={formData.phone}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="st-form-field st-style1">
                    <label>Products</label>
                    <div className="st-custom-select-wrap">
                      <select
                        name="department"
                        id="department"
                        className="st_select1"
                        data-placeholder="Select product"
                        onChange={handleInputChange}
                        value={formData.department}
                        required
                      >
                        <option>Select Product</option>
                        <option value="dental-care">Dental Care</option>
                        <option value="neurology">Neurology</option>
                        <option value="crutches">Crutches</option>
                        <option value="cardiology">Cardiology</option>
                        <option value="pulmonary">Pulmonary</option>
                        <option value="x-ray">X-Ray</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="st-form-field st-style1">
                    <label>Message</label>
                    <textarea
                      cols={30}
                      rows={10}
                      id="msg"
                      name="msg"
                      placeholder="Write something here..."
                      onChange={handleInputChange}
                      value={formData.msg}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    className="st-btn st-style1 st-color1 st-size-medium"
                    type="submit"
                    id="appointment-submit"
                    name="submit"
                  >
                    {loading ? "Sending..." : "Appointment"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Appointment;
